<template>
    <div>
        <b-modal id="choose-community-modal" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
          <div class="d-flex justify-content-end mb-2">
            <button
              class="btn ccm__close"
              @click="$bvModal.hide('choose-community-modal')"
            >
              <b-icon icon="x"></b-icon>
            </button>
          </div>
		<h4 class="text-center">Choose which workspace <br> you want to fill.</h4>
		<div v-if="!loading" class="ccm__list-community px-4 mt-4 pb-4">
			<div v-for="(data, index) in listCommunity" :key="index" class="ccm__card-community" @click="$emit('click', data._id)">
				<div class="d-flex align-items-center">
					<img v-if="data.community_picture" class="ccm__profile-img" :src="data.community_picture" :alt="data.community_name">
					<img v-else class="ccm__profile-img" :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.community_name" :alt="data.community_name">
					<div class="ml-3 d-flex flex-column">
						<span class="ccm_card__title mr-2">{{ truncatedText(data.community_name) }}</span>
						<span class="ccm_card__subtitle">{{ data.member ? data.member : '0' }} members</span>
					</div>
					<span class="ccm__ic-chevron-right">
						<font-awesome-icon icon="fa-solid fa-chevron-right" />
					</span>
				</div>
			</div>
		</div>
		<div v-else class="text-center my-5">
			Loading...
		</div>
        </b-modal>
    </div>
</template>

<script>
export default {
	data() {
		return {
			dummy: [
				{
					id: 112344123
				},
				{
					id: 1444555
				},
				{
					id: 2345123
				},
			]
		}
	},

	props : {
		listCommunity : {
			type: Array,
			default() {
				return []
			}
		},
		loading: {
			type: Boolean,
			default: true
		}
	},

	emits: ['click'],

	methods: {
		truncatedText(text) {
			if (text.length > 30) {
				return text.substring(0, 30) + "...";
			} else {
				return text;
			}
		}
	}
}
</script>

<style scoped>
.ccm__close {
	color: #fff;
}

.ccm__title {
	font-size: 20px;
	font-weight: 900;
}
.ccm__list-community {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 500px;
	display: grid;
	gap: 15px;
}

.ccm__card-community {
	background-color: #2D2D2D;
	border-radius: 10px;
	width: 100%;
	padding: 15px 25px;
	cursor: pointer;
	transition: background-color 0.25s;
}

.ccm__card-community:hover {
	background-color: #313030;
}

.ccm__profile-img {
	width: 37px;
	height: 37px;
	border-radius: 50%;
}

.ccm_card__title {
	color: white;
	font-size: 14px;
	font-weight: 900;
	margin: 0;
}

.ccm_card__subtitle {
	font-size: 10px;
	color: #8C8C8C;
}

.ccm__ic-chevron-right {
	font-size: 10px;
    margin-left: auto;
	margin-bottom: 14px;
	color: #8C8C8C;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#choose-community-modal .modal-content{
    border-radius: 12px !important;
    background-color: #222222;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}
</style>
