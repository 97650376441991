<template>
    <b-modal id="modal--package-discount" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content--pc_discount" dialog-class="cr__dialog d-flex justify-content-center" size="md" no-close-on-backdrop>
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button class="btn button--cr__close d-flex align-items-center justify-content-center" @click="$bvModal.hide('modal--package-discount')">
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>

                <div class="header--modal mt-1">
                    <h5 class="title--modal mb-3">{{ $t('package-discount--title') }}</h5>
                    <p class="desc--title__modal">{{ $t('package-discount--desc') }}</p>
                </div>
                
                <div class="package mt-4 d-flex align-items-center justify-content-center">
                    <div v-for="(pc, index) in list_package" :key="index" class="package--wrapper" :class="{'mr-4': index === 0}">
                        <div class="package--inner">
                            <div class="d-flex justify-content-end position-relative pt-2 pr-2">
                                <div class="pc--tooltip">
									<img src="@/assets/images/icons/ic_ictopup.png" alt="Information" class="pi--icon cursor-pointer">
                                    
									<div class="tooltips--content pb-0">
										<h3  class="pc--subtitle mb-2">{{ $t('about-creator--package') }}</h3>
										<ul class="pc--list pl-3 d-flex flex-column mb-2">
											<li>
												<span class="pc--item">
												    <b>10{{ $t('mins') }}</b> multi voice {{ $t('per-month') }}
											    </span>
											</li>
											<li>
												<span class="pc--item">
												    <b>5{{ $t('mins') }}</b> mono voice {{ $t('per-month') }}
											    </span>
										    </li>
										</ul>
										<p class="mb-0" style="color: #8C8C8C; font-size: 8px; text-align: left; font-style: italic">{{ $t('credits-will-be-renew-per-month') }}</p>

										<hr class="mt-2 mb-3" style="background: #585859; height: 0.1px;" />

										<h3  class="pc--subtitle mb-1">{{ $t('other-benefits') }}</h3>
										<ul class="pc--list pl-3 d-flex flex-column">
										    <li>
												<span class="pc--item">{{ $t('unlock-ai-weekend-club') }}</span>
											</li>
											<li>
												<span class="pc--item">{{ $t('community-membership') }}</span>
											</li>
										    <li>
												<span class="pc--item">{{ $t('unlock-referral-program') }}</span>
											</li>
											<li>
												<span class="pc--item">{{ $t('unlock-creator-program') }}</span>
											</li>
									    </ul>
								    </div>
								</div>
                            </div>
                            <div class="package--pr">
                                <div class="d-flex flex-column h-100">
                                <div class="d-flex align-items-center">
                                    <h4 class="text-left text-white prevent-select">{{ pc.package_name }}</h4>
                                    <span v-if="pc.save_price_percentage" class="badge--save">{{ $t('hemat') }} {{ pc?.save_price_percentage }}% + 5%</span>
                                </div>
                                <div class="mb-0 d-flex align-items-end mt-1">
                                    <span style="color: #FFF;font-size: 18px; font-style: normal;font-weight: 500;" class="mr-2">IDR</span> 
                                    <h3 v-if="pc?.price_monthly_strike" class="discount--text mr-2">{{ formatHarga(pc?.price_monthly_strike) || '-'}}</h3>
                                    <p class="m-0 sd__text">{{ formatHarga(pc?.price_monthly) || '-'}} </p>
                                    <span class="ml-1" style="color: #C4C4C4;font-size: 8px;font-style: italic;font-weight: 400;">{{ $t('per-mo') }}</span>
                                </div>
                                <p class="m-0 text-left" style="color: #C4C4C4;font-size: 12px;font-style: normal;font-weight: 400;">{{ $t('billed-annually') }} <span style="color: #C4C4C4;font-size: 12px;font-style: normal;font-weight: 600;">IDR {{ formatHarga(pc?.price_discount) || '-'}}</span></p>

                                <div class="d-flex justify-content-center my-4">
                                    <button class="btn btn-block" :class="{ 'button__item-active' : pc?.package_active, 'btn-pricing' : !pc?.package_active}" :disabled="load_topup || pc?.package_active"  @click="pc?.package_active ? '' : $emit('click:checkout', pc._id)">{{ pc?.package_active ? $t('active') : $t('claim-now') }}</button>
                                </div>

                                <div class="text-left mb-3">
                                    <h6 style="font-size: 12px" class="text-white mb-1">{{ pc?.total_minutes_audio_multilingual_duration || "-" }}{{ $t('minutes') }} multi voice (~{{ pc?.total_hour_audio_multilingual_duration || "-" }} {{ $t('hrs') }})</h6>
                                    <h6 style="font-size: 12px" class="text-white mb-1">{{ pc?.total_minutes_audio_duration || "-" }}{{ $t('minutes') }} mono voice (~{{ pc?.total_hour_audio_duration || "-" }} {{ $t('hrs') }})</h6>
                                </div>

                                    <span style="font-size: 10px" class="text-white mb-3 text-left">{{ $t('extra-benefit') }}</span>

                                    <div class="sd__item d-flex justify-content-between mb-3">
                                        <div class="sd__item-inner d-flex flex-column">

                                            <div class="d-flex align-items-center mb-1" v-if="!pc.swc_quota">
                                                <img src="@/assets/images/icons/ic_check-mb.svg" width="11" height="11">
                                                <p class="sd__text-list mb-0 ml-2">{{ pc?.voice_characters || '-' }} {{ $t('voice-characters') }}</p>
                                            </div>
                                            <div class="d-flex align-items-center mb-1" v-if="!pc.swc_quota">
                                                <img src="@/assets/images/icons/ic_check-mb.svg" width="11" height="11">
                                                <p class="sd__text-list mb-0 ml-2">{{ pc?.voice_model_multilingual || '-' }} {{ $t('voice-clone') }}</p>
                                            </div>
                                            <div class="d-flex align-items-center mb-1">
                                                <img src="@/assets/images/icons/ic_check-mb.svg" width="11" height="11">
                                                <p class="sd__text-list mb-0 ml-2">{{ $t('unlimited-ai-script-writer') }}</p>
                                            </div>
                                            <div class="d-flex align-items-center mb-1">
                                                <img src="@/assets/images/icons/ic_check-mb.svg" width="11" height="11">
                                                <p class="sd__text-list mb-0 ml-2">{{ $t('unlimited-projects-music-video') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
		</div>
    </b-modal>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
    mixins: [AlertUtils],
    props: {
        list_data_discount: {
            type: Array,
            default: () =>{
                return []
            }
        },
        load_topup: {
            type: Boolean,
            default: false
        }
    },
	data() {
		return {
            list_package: []
		}
	},

    watch: {
        list_data_discount(val){
            if(val.length > 0){
                this.list_package = val
            }
        }
    },

	methods: {
        formatPrice(price) {
            // Function yang digunakan untuk memformat harga
            return `IDR ${price.toLocaleString()}`;
        },
        formatHarga(angka) {
			if (angka >= 1000000) {
				return (angka / 1000).toFixed(0) + 'k';
			} else if (angka >= 1000) {
				return (angka / 1000).toFixed(0) + 'k';
			} else {
				return angka.toLocaleString('id-ID', { maximumFractionDigits: 0 });
			}
		},
	}
}
</script>

<style scoped>
.tooltips--content{
	padding: 25px;
	background: #1F1F1F;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	position: absolute;
	z-index: 2;
	visibility: hidden;
	opacity: 0;
	transform-origin: center right;
  	transition: all 0.25s;
    left: 6px;
    top: 33px;
}
.pc--list li{
	width: fit-content;
}
.pc--subtitle{
	font-size: 10px;
	color: #8C8C8C;
	text-align: left;
}
.pc--item{
	font-size: 10px;
	color: #fff;
	text-align: left;
}
.pc--tooltip:hover .tooltips--content{
	visibility: visible;
	opacity: 1;	
}
.package--pr{
    padding: 0px 15px 12.5px 15px;
}
.sd__text {
	color: #FFF;
	font-size: 25px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}
.btn-pricing {
	transition: 0.5s;
	background-color: #6D75F6;
	border: none;
	border-radius: 4px;
	color: white;
	font-weight: 900;
	font-size: 15px;
    padding: 5px;
	transition: background-color 0.25s;
}

.btn-pricing:hover {
	background-color: #545EED;
}
.button__item-active, .button__item-active:disabled {
	color: #FFF;
	font-style: normal;
	font-size: 15px;
	font-style: normal;
	font-weight: 900;
	width: 100%;
	background-color: #2d2d2d;
	border-radius: 4px;
	border: none;
	cursor: default !important;
}
.pi--icon {
	width: 15px;
	height: 15px
}
.active--for1month{
	color: #C4C4C4;
	font-size: 12px;
	text-align: left;
}
.discount--text{
	color: #8C8C8C;
	font-family: "Avenir Next";
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration: line-through;
}
.badge--save{
	color: #FFF;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	border-radius: 14px;
	background: rgba(109, 117, 246, 0.30);
	padding: 4px 8px;
    margin-left: 0.5rem;
}
.details:hover .list__detail{
    visibility: visible;
    top: 36px;
    left: -74px;
    transition: .25s all;
}
.list__detail{
    visibility: hidden;
    position: absolute;
    color: #fff;
    border-radius: 10px;
    background: #1F1F1F;
    padding: 16px;
    top: 36px;
    left: -74px;
    text-align: left;
    transition: .25s all;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
}
.list__detail span{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-wrap: nowrap;
}
.list__detail h6{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}
.list__detail ul li{
    padding-bottom: 5px;
}
.list__detail ul{
    list-style: none;
}

.package--name{
    color: #FFF;
    text-align: left;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.package--wrapper{
    padding: 2px;
    background-image: linear-gradient(to bottom, #FFFFFF33, #FFFFFF00);
    border-radius: 8px;
    
}
.package--inner{
    background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
    border-radius: 8px;
    backdrop-filter: blur(2px);
}
.sd__text-list {
	color: #FFF;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.discount{
    color: #585859;
    text-align: center;
    font-family: 'Avenir Next';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
}
.price{
    color: #FFF;
    text-align: center;
    font-family: 'Avenir Next';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}
.package{
    color: #fff;
    text-align: center;
}
.btn--purple:hover{
    background-color: #473ff4;
}
.btn--purple{
    border-radius: 4px;
    background-color: #6D75F6;
    color: #fff;
    transition: .25s all ease-in-out;
    padding: 3px 23.5px;
}
.info{
    color: #C4C4C4;
    font-family: 'Avenir Next';
    font-size: 8px;
    font-style: italic;
    font-weight: 400;
}
.details{
    color: #6D75F6;
    font-family: 'Avenir Next';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}
.title--modal{
    color: #FFF;
    text-align: center;
    font-family: 'Avenir Next';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.desc--title__modal{
    color: #FFF;
    text-align: center;
    font-family: 'Avenir Next';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#modal--package-discount .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content--pc_discount {
	width: 529px !important;
}
</style>
