<template>
	<div>
	<!-- <b-overlay :show="load_topup" rounded="sm" variant="dark" spinner-variant="primary"> -->
		<div class="top-up__container position-relative">
			<div class="top-up__container__nav">
				<button
					type="button"
					class="top-up__back-button btn d-flex align-items-center p-0 rounded-none"
					@click="$router.push({ name : 'Home' })"
				>
					<img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2 cursor-pointer">
					<h1 class="top-up__title text-white">Top Up</h1>
				</button>
				<div class="card-nav__wrapper">
					<div class="card-nav__swc">
						<div class="card-swc__inner">
							<span class="mb-0 card-nav__subtitle">{{ $t('swc-balance') }}</span>
							<div class="d-flex">
								<img src="@/assets/images/sidebar/sb_swc.png" alt="SWC" class="img-fluid" width="23px" height="29px">
								<span class="card-nav__title ml-1">{{ status_subscriptions.swc_quota ? status_subscriptions.swc_quota : '0' }}</span>
							</div>
						</div>
						<div class="vertical-line"></div>
						<div class="list-action__swc">
							<button class="btn btn-topup" disabled>
								<img src="@/assets/images/icons/ic_topup_send.svg" alt="Send" title="Send">
								<span>{{ $t('send-swc') }}</span>
							</button>
							<button class="btn btn-topup" @click="$bvModal.show('topup-swc-modal')">
								<img src="@/assets/images/icons/ic_topup_plus.svg" alt="Top Up" title="Top Up">
								<span>Top Up</span>
							</button>
							<button class="btn btn-topup" disabled>
								<img src="@/assets/images/icons/ic_topup_explore.svg" alt="Explore" title="Explore">
								<span>{{ $t('explore') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="top-up__container-inner">
				<div class="d-flex justify-content-center w-100">
					<div class="slider--switch">
						<input type="radio" name="package" id="monthly" value="monthly" v-model="package_period" :checked="package_period == 'monthly'" hidden/>
						<label for="monthly">Monthly</label>  
						<input type="radio" name="package" id="annual" value="annual"  v-model="package_period" :checked="package_period == 'annual'" hidden/>
						<label for="annual">Annual <span style="color: #6D75F6 !important">Save up to 59%</span></label> 
						<div class="switch--bar"></div>
					</div>
				</div>

				<div class="tab-content" id="topupTabContent">
					<div class="tab-pane fade show active text-white" id="package" role="tabpanel" aria-labelledby="package-tab">
							<div v-for="(data, index) in listPackageTopUp" :key="index" class="package">
								<div class="package--title__container d-flex align-items-center" :class="index != 0 ? 'mt-4' : ''">
									<h3 class="package__title" :style="{ marginStart: '40px' }">{{ data.package_category_name }}</h3>
									<div class="d-flex position-relative">
										<img src="@/assets/images/icons/ic_ictopup.png" alt="Information" @mouseover="showTooltip(index)" @mouseout="hideTooltip()" class="pi--icon">
										<div class="ptc--tooltip" :class="{ 'ptc--tooltip--show' : activeTooltip === index }">
											<span class="ptct--subtitle">{{ $t('about-package') }}</span>
											<h5 class="ptct--title">{{ index == 0 ? $t('special-deals') : index == 1 ? 'Extra Credits' : 'Professional' }}</h5>
											<span v-if="index == 0" class="ptct--desc">{{  $t('about-special-deals')  }}</span>
											<div v-else>
												<div v-if="index == 1" class="ptct--desc" v-html="$t('about--extra-credits')"></div>
												<span v-else-if="index == 2" class="ptct--desc">
													The Professional package is designed to elevate collaboration within your Professional. It combines powerful features and resources to foster engagement and productivity.
												</span>
												
											</div>
										</div>
									</div>
								</div>
								<div class="container-fluid p-0">
									<div class="d-flex align-items-center my-4">
										<div class="position-relative" :class="{ 'slider__wrapper': data.package_category_name == 'Special Deals', 'slider__wrapper--icons': data.package_category_name == 'Extra Credits'}">
											<div
												class="slider__header position-absolute d-flex flex-column justify-content-center align-items-start"
												:style="{
													opacity: slidersHeaderShowPercentage[index] / 100,
													translate: `-${(100 - slidersHeaderShowPercentage[index]) * 0.4}px 0`,
												}"
											>
	
												<h2 class="slider__header-title d-flex flex-column">
													<template v-if="index === 0">
														<span class="slider__header-text text-white">{{ $t('supporting') }}</span>
														<span class="slider__header-text slider__header-text--highlight" v-html="$t('new-creators')"></span>
														<span class="slider__header-text text-white text-nowrap">{{ $t('with-special-price') }}</span>
													</template>
													<template v-else-if="index === 1">
														<span class="slider__header-text text-white">{{ $t('protect-your-content') }}</span>
														<span class="slider__header-text slider__header-text--highlight" v-html="$t('top-up-now')"></span>
													</template>
													<template v-else>
														<span class="slider__header-text text-white">Generating</span>
														<span class="slider__header-text slider__header-text--highlight">Time</span>
														<span class="slider__header-text text-white">generating more</span>
														<span class="slider__header-text slider__header-text--highlight">Money</span>
													</template>
												</h2>
											</div>
										</div>
										<div
											ref="sliders"
											class="row slider"
											@mousedown="handleMousedownSlider(index, $event)"
											@mousemove="handleMousemoveSlider(index, $event)"
											@mouseup="handleMouseupSlider(index)"
											@mouseleave="handleMouseleaveSlider(index)"
											@scroll="handleSliderHeaderVisibility(index)"
										>
											<template v-if="load_package">

												<template v-if="index == 0">
													<div v-for="idx_shim in 4" :key="idx_shim" class="card-package__container special--deals">
														<div class="card-package__wrapper h-100 mr-3">
															<div class="card-package pl-4 pr-2 h-100 pt-2">
																<div class="d-flex flex-column h-100 pt-3">
																	<b-skeleton class="mt-3" width="70%"></b-skeleton>
																	<div class="mb-0 d-flex align-items-center">
																		<b-skeleton class="mt-3 mr-3" width="10%"></b-skeleton>
																		<b-skeleton class="mt-3" width="50%"></b-skeleton>
																	</div>
																	<div class="mb-0 d-flex align-items-center mt-1">
																		<b-skeleton class="mt-3" width="45%"></b-skeleton>
																	</div>
																	<div class="d-flex justify-content-center my-2">
																		<b-skeleton class="mt-3" width="100%" height="80%"></b-skeleton>
																	</div>
	
																	<b-skeleton class="mt-3" width="80%"></b-skeleton>
		
		
																		<!-- <div class="d-flex align-items-end">
																			<b-skeleton class="mt-3" width="30%"></b-skeleton>
																			<b-skeleton class="mt-3" width="10%"></b-skeleton>
																		</div>
																		<b-skeleton class="mt-3" width="40%"></b-skeleton>
			
																		<b-skeleton class="mt-3" width="30%"></b-skeleton> -->
		
																	<div class="mb-3 mt-2">
																			<div class="d-flex align-items-center">
																				<b-skeleton class="mt-3 mr-2" width="8%"></b-skeleton>
																				<b-skeleton class="mt-3" width="90%"></b-skeleton>
																			</div>
																			<div class="d-flex align-items-center">
																				<b-skeleton class="mt-3 mr-2" width="8%"></b-skeleton>
																				<b-skeleton class="mt-3" width="90%"></b-skeleton>
																			</div>
																			<div class="d-flex align-items-center">
																				<b-skeleton class="mt-3 mr-2" width="8%"></b-skeleton>
																				<b-skeleton class="mt-3" width="90%"></b-skeleton>
																			</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</template>
												<template v-if="index == 1">
													<div v-for="idx_shim in 4" :key="idx_shim" class="card-package__container special--deals">
														<div class="card-package__wrapper h-100 mr-3">
															<div class="card-package pl-4 pr-2 h-100 py-2">
																<div class="d-flex flex-column h-100">
																	<div class="mb-0 d-flex align-items-center justify-content-center">
																		<b-skeleton class="mt-3" width="50%"></b-skeleton>
																	</div>
																	<div class="mb-0 d-flex align-items-center justify-content-center mt-4">
																		<b-skeleton type="avatar"></b-skeleton>
																		<div class="d-flex flex-column w-100">
																			<b-skeleton class="ml-2 mb-2" width="60%"></b-skeleton>
																			<b-skeleton class="ml-2" width="80%"></b-skeleton>
																		</div>
																	</div>
																	<div class="d-flex justify-content-center mb-2 mt-3">
																		<b-skeleton class="mt-3" width="100%" height="80%"></b-skeleton>
																	</div>
	
																</div>
															</div>
														</div>
													</div>
												</template>

											</template>
											<template v-else>
												<div class="card-package__container special--deals" v-for="(detail, index_package) in data.package_list" :key="index_package">
														<div v-if="detail?.card_style == 'list-text'" class="card-package__wrapper h-100 mr-3 text-center">
															<div class="card-package pl-4 pr-2 h-100 pt-2">
																<div class="d-flex justify-content-end position-relative">
																	<div class="pc--tooltip">
																			<img src="@/assets/images/icons/ic_ictopup.png" alt="Information" class="pi--icon cursor-pointer">
																			<div class="tooltips--content">
																				<h3  class="pc--subtitle mb-2">{{ detail.package_name == "Creator" ? $t('about-creator--package') : detail.package_name == "Professional" ? $t('about-professional--package') : '-' }}</h3>
																				<ul class="pc--list pl-3 d-flex flex-column mb-2">
																					<li>
																						<span class="pc--item">
																							<b>{{ detail.package_name == "Creator" ? '10' : detail.package_name == "Professional" ? '20' : '-' }}{{ $t('mins') }}</b> multi voice {{ $t('per-month') }}
																						</span>
																					</li>
																					<li>
																						<span class="pc--item">
																							<b>{{ detail.package_name == "Creator" ? '5' : detail.package_name == "Professional" ? '10' : '-' }}{{ $t('mins') }}</b> mono voice {{ $t('per-month') }}
																						</span>
																					</li>
																				</ul>
																				<p class="mb-0" style="color: #8C8C8C; font-size: 8px; text-align: left; font-style: italic">{{ package_period == 'annual' ? $t('credits-will-be-renew-per-month') : $t('subscription-needs-to-be-renewed-monthly') }}</p>
		
																				<hr class="mt-2 mb-3" style="background: #585859; height: 0.1px;" />
		
																				<h3  class="pc--subtitle mb-1">{{ $t('other-benefits') }}</h3>
																				<ul class="pc--list pl-3 d-flex flex-column">
																					<li>
																						<span class="pc--item">{{ $t('unlock-ai-weekend-club') }}</span>
																					</li>
																					<li>
																						<span class="pc--item">{{ $t('community-membership') }}</span>
																					</li>
																					<li>
																						<span class="pc--item">{{ $t('unlock-referral-program') }}</span>
																					</li>
																					<li>
																						<span class="pc--item">{{ $t('unlock-creator-program') }}</span>
																					</li>
																				</ul>
																		</div>
																	</div>
																</div>
																<div class="d-flex flex-column h-100">
																	<div class="d-flex align-items-center">
																		<h4 class="text-left text-white prevent-select">{{ detail.package_name }}</h4>
																		<span v-if="detail.save_price_percentage && package_period == 'annual'" class="badge--save">{{ $t('hemat') }} {{ detail?.save_price_percentage }}%</span>
																	</div>
																	<div class="mb-0 d-flex align-items-end mt-1">
																		<span style="color: #FFF;font-size: 18px; font-style: normal;font-weight: 500;" class="mr-2">IDR</span> 
																		<h3 v-if="detail?.price_monthly_strike && package_period == 'annual'" class="discount--text mr-2">{{ formatHarga(detail?.price_monthly_strike) || '-'}}</h3>
																		<p class="m-0 sd__text">{{ package_period == 'annual' ? formatHarga(detail?.price_monthly) : formatHarga(detail?.price_monthly_strike)}} </p>
																		<span class="ml-1" style="color: #C4C4C4;font-size: 8px;font-style: italic;font-weight: 400;">{{ $t('per-mo') }}</span>
																	</div>
																	<p class="m-0 text-left" style="color: #C4C4C4;font-size: 12px;font-style: normal;font-weight: 400;" :class="{'v-hidden': package_period == 'monthly'}">{{ $t('billed-annually') }} <span style="color: #C4C4C4;font-size: 12px;font-style: normal;font-weight: 600;">IDR {{ formatHarga(detail?.price_idr) || '-'}}</span></p>
		
																	<div class="d-flex justify-content-center my-4 pr-3">
																		<button class="btn btn-block position-relative" 
																			:class="{ 
																			'button__item-active' : !detail?.package_active && detail?.button_buy_disable || detail?.package_active && !detail?.button_buy_disable, 
																			'btn-pricing' : detail?.package_active && detail?.button_buy_disable || !detail?.package_active && !detail?.button_buy_disable,
																			'tooltip--btn' : !detail?.package_active && detail?.button_buy_disable
																			}" 
																			:disabled="!detail?.package_active && detail?.button_buy_disable || detail?.package_active && !detail?.button_buy_disable"
																			@click="$router.push({name: 'PaymentMethod', query: { packageId: detail._id, packageBill: package_period}})">{{ detail.label_button == 1 ? $t('active') : detail.label_button == 2 ? $t('buy-now') : detail.label_button == 3 ? $t('upgrade-now--upper') : $t('contact-sales') }}
																			<div class="tooltip--btntext">{{ $t('tooltips-package-annual') }}</div>
																		</button>
																	</div>
		
																	<div class="text-left mb-3" v-if="package_period == 'annual'">
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ detail?.total_minutes_audio_multilingual_duration || "-" }}{{ $t('minutes') }} multi voice (~{{ detail?.total_hour_audio_multilingual_duration || "-" }} {{ $t('hrs') }})</h6>
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ detail?.total_minutes_audio_duration || "-" }}{{ $t('minutes') }} mono voice (~{{ detail?.total_hour_audio_duration || "-" }} {{ $t('hrs') }})</h6>
																	</div>
																	<div class="text-left mb-3" v-else>
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ detail?.audio_multilingual_duration || "-" }}{{ $t('minutes') }} multi voice</h6>
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ detail?.audio_duration || "-" }}{{ $t('minutes') }} mono voice</h6>
																	</div>
		
																	<span style="font-size: 10px" class="text-white mb-3 text-left">{{ $t('extra-benefit') }}</span>
		
																	<div class="sd__item d-flex justify-content-between mb-3">
																		<div class="sd__item-inner d-flex flex-column">
		
																			<div class="d-flex align-items-center" v-if="!detail.swc_quota">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ detail?.voice_characters || '-' }} {{ $t('voice-characters') }}</p>
																			</div>
																			<div class="d-flex align-items-center" v-if="!detail.swc_quota">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ detail?.voice_model_multilingual || '-' }} {{ $t('voice-clone') }}</p>
																			</div>
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-ai-script-writer') }}</p>
																			</div>
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-projects-music-video') }}</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div v-else-if="detail?.card_style == 'business'" class="card-package__wrapper h-100 mr-3 text-center">
															<div class="card-package pl-4 pr-2 h-100 pt-2">
																<!-- <div class="d-flex justify-content-end">
																	<img src="@/assets/images/icons/ic_ictopup.png" alt="Information" class="pi--icon">
																</div> -->
																<div class="d-flex flex-column h-100 pt-3">
																	<div class="d-flex align-items-center">
																		<h4 class="text-left text-white prevent-select">{{ detail.package_name }}</h4>
																	</div>
																	<div class="mb-0 d-flex align-items-center mt-1">
																		<p class="mt-2 package--title">{{ $t('custom-pricing') }}</p>
																	</div>
		
																	<div class="d-flex justify-content-center my-4">
																		<button class="btn btn-block btn--outline-white mr-3" @click="directWhatsapp()">{{ $t('contact-sales') }}</button>
																	</div>
		
																	<div class="text-left mb-3">
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ $t('custom-quotas-voice-generations') }}</h6>
																		<h6 style="font-size: 12px" class="text-white mb-1">{{ $t('collaborative-workspace') }}</h6>
																	</div>
		
																	<span style="font-size: 10px" class="text-white mb-3 text-left">{{ $t('extra-benefit') }}</span>
		
																	<div class="sd__item d-flex justify-content-between mb-3">
																		<div class="sd__item-inner d-flex flex-column">
		
																			<div class="d-flex align-items-center" >
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('access-all-features') }}</p>
																			</div>
																			<div class="d-flex align-items-center" >
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('enterprise-level-slas') }}</p>
																			</div>
																			
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-ai-script-writer') }}</p>
																			</div>
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-projects-music-video') }}</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div v-else-if="detail?.card_style == 'starter'" class="card-package__wrapper h-100 mr-3 text-center">
															<div class="card-package pl-4 pr-2 h-100 pt-2">
																<div class="d-flex flex-column h-100 pt-3">
																	<h4 class="text-left text-white prevent-select">{{ detail.package_name }}</h4>
																	<div class="mb-0 d-flex align-items-center mt-1">
																		<span style="color: #FFF;font-size: 18px; font-style: normal;font-weight: 500;" class="mr-2">IDR</span> 
																		<p class="m-0 sd__text">{{ formatPrice(detail?.price_idr) || '-'}} </p>
																	</div>
																	<span class="active--for1month"
																		:class="{
																			'mb-1': !detail?.package_active && detail?.button_buy_disable || detail?.package_active && !detail?.button_buy_disable
																		}">{{ $t('active-for-1-month') }}</span>
		
																	<div class="d-flex justify-content-center my-4">
																		<button class="btn btn-block mr-3" 
																			:class="{ 
																				'button__item-active' : !detail?.package_active && detail?.button_buy_disable || detail?.package_active && !detail?.button_buy_disable, 
																				'btn--outline-white': detail?.package_active && detail?.button_buy_disable || !detail?.package_active && !detail?.button_buy_disable,
																				'tooltip--btn' : !detail?.package_active && detail?.button_buy_disable
																			}"
																			:disabled="!detail?.package_active && detail?.button_buy_disable || detail?.package_active && !detail?.button_buy_disable"
																			@click="$router.push({name: 'PaymentMethod', query: { packageId: detail._id, packageBill: package_period}})">
																			{{ detail.label_button == 1 ? $t('active') : detail.label_button == 2 ? $t('buy-now') : detail.label_button == 3 ? $t('upgrade-now--upper') : $t('contact-sales') }}
																			<div class="tooltip--btntext">{{ $t('tooltips-package-annual') }}</div>
																		</button>
																	</div>
		
																	<div class="d-flex align-items-end">
																		<p class="m-0 sd__text" style="line-height: 18px;">+{{ detail?.audio_multilingual_duration }}</p>
																		<span style="font-size: 10px" class="text-white ml-1">{{$t('minutes')}}</span>
																	</div>
																	<h6 class="sd__text--sm mt-1 mb-3 text-left">Multilingual Voice</h6>
		
																	<span style="font-size: 10px" class="text-white mb-3 text-left">{{ $t('extra-benefit') }}</span>
		
																	<div class="sd__item d-flex justify-content-between mb-3">
																		<div class="sd__item-inner d-flex flex-column">
		
																			<div class="d-flex align-items-center" v-if="detail.voice_characters">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ detail?.voice_characters || '-' }} {{ $t('voice-characters') }}</p>
																			</div>
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-ai-script-writer') }}</p>
																			</div>
																			<div class="d-flex align-items-center">
																				<img src="@/assets/images/icons/ic_check-mb.svg" width="12" height="12">
																				<p class="sd__text-list mb-0 ml-1">{{ $t('unlimited-projects-music-video') }}</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div v-else-if="detail?.card_style == 'icon'" class="card-package__wrapper h-100 extra--credits">
															<div class="card-package h-100 d-flex flex-column align-items-center justify-content-between">
																<h4 class="text-center text-white mb-4 prevent-select">{{ detail.package_name }}</h4>
																<div v-if="detail.total_item == 2" class="container-fluid mb-4">
																	<div v-if="detail.question_generates" class="row align-items-center prevent-select">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_question.png" alt="question" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.question_generates }}</h3>
																				<p style="font-size: 12px;" class="mb-0">questions</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.audio_duration" class="row align-items-center prevent-select" :class="detail.question_generates ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.audio_duration }}</h3>
																				<p style="font-size: 12px;" class="mb-0">{{ $t('minutes') }}</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.swc_quota" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_swc.png" alt="swc" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.swc_quota }}</h3>
																				<p style="font-size: 12px;" class="mb-0">SWC</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.account_quota" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration || detail.swc_quota ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_account.png" alt="account" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">{{ detail.account_quota }}</h3>
																				<p style="font-size: 12px;" class="mb-0">account</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.video_duration" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration || detail.swc_quota || detail.account_quota ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_video.png" alt="video" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.video_duration }}</h3>
																				<p style="font-size: 12px;" class="mb-0">seconds</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.audio_multilingual_duration" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration || detail.swc_quota || detail.account_quota || detail.video_duration ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_audio2.png" alt="video" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.audio_multilingual_duration }}</h3>
																				<p style="font-size: 12px;" class="mb-0">minutes</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.voice_model_multilingual" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration || detail.swc_quota || detail.account_quota || detail.video_duration || detail.audio_multilingual_duration ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_model.png" alt="video" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.voice_model_multilingual }}</h3>
																				<p style="font-size: 12px;" class="mb-0">voice models</p>
																			</div>
																		</div>
																	</div>
																	<div v-if="detail.image_generates" class="row align-items-center prevent-select" :class="detail.question_generates || detail.audio_duration || detail.swc_quota || detail.account_quota || detail.video_duration || detail.audio_multilingual_duration || detail.voice_model_multilingual ? 'mt-3' : ''">
																		<div class="col-6 d-flex justify-content-center">
																			<img src="@/assets/images/icons/ic_package_img.png" alt="img" class="img-fluid card-package__icon">
																		</div>
																		<div class="col-6">
																			<div class="ml-2">
																				<h3 class="package__title">+{{ detail.image_generates }}</h3>
																				<p style="font-size: 12px;" class="mb-0">images</p>
																			</div>
																		</div>
																	</div>
																</div>
																<div v-else class="container-fluid mb-3">
																	<div class="d-flex align-items-center" :class="detail.total_item >= 3 ? 'justify-content-between' : 'justify-content-center'">
																		<div v-if="detail.question_generates" class="text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_question.png" alt="question" class="img-fluid card-package__icon">
																			<h3 class="package__title mt-2">+{{ detail.question_generates }}</h3>
																			<p style="font-size: 12px;" class="mb-0">questions</p>
																		</div>
																		<div v-if="detail.audio_duration" class="d-flex align-items-center text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid card-package__icon">
																			<div>
																				<div class="d-flex align-items-end">
																					<h3 class="package__title mt-2">+{{ detail.audio_duration }}</h3>
																					<p style="font-size: 10px;" class="mb-0">{{  $t('minutes') }}</p>
																				</div>
																				<p style="font-size: 12px;" class="mb-0">Monolingual Voice</p>
																			</div>
																		</div>
																		<div v-if="detail.audio_multilingual_duration" class="d-flex align-items-center text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid card-package__icon">
																			<div>
																				<div class="d-flex align-items-end">
																					<h3 class="package__title mt-2">+{{ detail.audio_multilingual_duration }}</h3>
																					<p style="font-size: 10px;" class="mb-0 ml-1">{{  $t('minutes') }}</p>
																				</div>
																				<p style="font-size: 12px;" class="mb-0">Multilingual Voice</p>
																			</div>
																		</div>
																		<div v-if="detail.voice_model_multilingual" class="text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid card-package__icon">
																			<h3 class="package__title mt-2">+{{ detail.voice_model_multilingual }}</h3>
																			<p style="font-size: 12px;" class="mb-0">{{  $t('minutes') }}</p>
																		</div>
																		<div v-if="detail.swc_quota" class="text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_swc.png" alt="swc" class="img-fluid card-package__icon">
																			<h3 class="package__title mt-2">+{{ detail.swc_quota }}</h3>
																			<p style="font-size: 12px;" class="mb-0">SWC</p>
																		</div>
																		<div v-if="detail.account_quota" class="text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_account.png" alt="account" class="img-fluid card-package__icon">
																			<h3 class="package__title mt-2">{{ detail.account_quota }}</h3>
																			<p style="font-size: 12px;" class="mb-0">account</p>
																		</div>
																		<div v-if="detail.video_duration" class="text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_video.png" alt="video" class="img-fluid card-package__icon">
																			<h3 class="package__title mt-2">{{ detail.video_duration }}</h3>
																			<p style="font-size: 12px;" class="mb-0">seconds</p>
																		</div>
																		<div v-if="detail.image_generates" class="d-flex align-items-center text-center prevent-select">
																			<img src="@/assets/images/icons/ic_package_img.png" alt="img" class="img-fluid card-package__icon">
																			<div>
																				<div class="d-flex align-items-end">
																					<h3 class="package__title mt-2">+{{ detail.image_generates }}</h3>
																				</div>
																				<p style="font-size: 12px;" class="mb-0">{{ $t('text-to-image') }}</p>
																			</div>
																		</div>
																	</div>
																</div>
																<button class="btn btn-pricing btn-block" style="font-size: 20.72px !important" @click="!detail.package_community ? (detail.price_idr ? createInvoice(detail._id) : checkStatusPin(detail._id)) : communityCheck(detail._id)">{{ detail.price_idr ? formatPrice(detail.price_idr) : detail.price_swc + ' SWC'}}</button>
																<div class="active__label position-absolute" v-if="detail.package_name == 'Multi Starter'">
																	<p class="m-0 footer__text"><i>{{ $t('multi-go--note') }}</i></p>
																</div>
															</div>
														</div>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>

			<div class="assistant--wrap w-100">
				<div class="assistant--text__wrapper position-absolute" :class="{ 'visible' : isAssistantHovered }">
					<p class="mb-0 mr-3">{{  $t('payment-help') }}</p>
				</div>
				<button class="btn button--assistant position-relative d-flex align-items-center p-0 ml-auto mr-4" @click="whatsapp()" @mouseenter="showAssistant" @mouseleave="hideAssistant">
					<img src="@/assets/images/icons/wa_topup-logo.svg" alt="" class="wa--logo__assistant">
				</button>
			</div>
		</div>
		<SetupPin :package-id="id_package" @setup-pin="requestPin"/>
		<Soca v-if="load_topup"/>
		<VerificationCode v-on:otp-success="openCreatePin"/>
		<CreatePin name="topup-create-pin-modal" type="create"/>
		<ModalTopupSwc :list-package="listPackageSwc" :choosed-index="choosedIndex" :total-price="totalPrice" :status-disable="isButtonDisable" :is-enough="is_enough" :load-swc="load_swc_price" v-model="custom_swc" @update:choosedIndex="handleChoosedIndex" @swc-focus="swcCustomFocus" @inputChange="handleInputSwc" @next="next()" @close-topup="handleCloseTopUp"/>
		<ChooseCommunity @click="handleChooseCommunity" :list-community="list_community" :loading="load_community"/>
		<CreateCommunity @create-community="handleCreateCommunity"/>
		<ModalPackageError @click:upgrade="(id) => createInvoice(id)" :tipe_package="tipe_package"/>
		<ModalPackageDiscount :list_data_discount="list_data_discount" @click:checkout="createInvoice" :load_topup="load_topup"/>
		<ModalSWCInsufficient />
	<!-- </b-overlay> -->
	</div>
</template>

<script>
import { packageTopUpCeator, topupSwc } from '@/services/subscriptions/subscriptions.service'
import { checkStatusPin, requestSetPin } from '@/services/pin/pin.service'
import { createSwcPayment, checkPriceSwc, customSwcRequest, getPackageTopupSwc } from "@/services/swc_payment/swc_payment.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { checkCommunity, getOwnershipCommunity } from '@/services/community/community.service'
import { checkDiscount } from '@/services/referral/referral.service';

import SetupPin from '@/components/payment/modals/SetupPin'
import VerificationCode from '@/components/pin/VerificationCode'
import CreatePin from '@/components/pin/CreatePin'
import ModalTopupSwc from '@/components/topup-swc/ModalTopupSwc'
import ChooseCommunity from '@/components/community/modal/ChooseCommunity'
import CreateCommunity from '@/components/community/modal/CreateCommunity'
import Soca from '@/components/loading/Soca'
import ModalPackageError from '@/components/modal/ModalPackageError'
import ModalPackageDiscount from '@/components/package/ModalPackageDiscount';
import ModalSWCInsufficient from '@/components/modal/ModalSWCInsufficient';

export default {
	components: {
		SetupPin,
		VerificationCode,
		CreatePin,
		ModalTopupSwc,
		ChooseCommunity,
		CreateCommunity,
		Soca,
		ModalPackageError,
		ModalPackageDiscount,
		ModalSWCInsufficient
	},
	mixins: [AlertUtils],
	data() {
		return {
			package_period: "annual",
			tipe_package: "",
			package_id: "",
			list_data_discount: [],
			status_discount_user: false,
			list : [
                {
                    swc : 50,
                    price : 10000,
                    img : 'ic_coin_swc_1.png'
                },
                {
                    swc : 100,
                    price : 20000,
                    img : 'ic_coin_swc_2.png'
                },
                {
                    swc : 150,
                    price : 40000,
                    img : 'ic_coin_swc_3.png'
                },
                {
                    swc : 200,
                    price : 50000,
                    img : 'ic_coin_swc_4.png'
                },
            ],

			listPackageSwc : [],
			listPackageTopUp : [],
			status_subscriptions : [],
			list_community : [],

			load_package : false,
			load_package_swc : false,
			load_topup : false,
			load_swc_price : false,
			load_subscriptions : false,
			load_community : false,
			is_enough : false,
			isAssistantHovered : false,

            choosedIndex : null,
			id_package: null,
			custom_swc: null,
			activeTooltip: null,
			activeTooltipPc: null,
            totalPrice : 0,
			swc_price : 0,

			slidersStartX: [],
			slidersStartScrollLeft: [],
			slidersHeaderShowPercentage: [],
		}
	},

	mounted(){
		this.checkDiscountUserReferral();
		
		document.body.classList.add('overflow-x-hidden');
		this.getPackageTopUp('annual')
		this.getSwcPrice()
		this.getStatusSubscriptions()
		this.getPackageSwc()
		this.getCommunity()
		
	},

	beforeDestroy() {
		document.body.classList.remove('overflow-x-hidden');
		window.removeEventListener('resize', this.handleAllSliderHeaderVisibility);
	},

	watch: {
		status_discount_user(val){
			if(val){
				this.$bvModal.show('modal--package-discount');
			}
		},
		custom_swc(val) {
			if(val) {
				this.choosedIndex = null
				this.totalPrice = val * this.swc_price
				if(val >= 50) {
					this.is_enough = true
				} else {
					this.is_enough = false
				}
			} else {
				if(!this.choosedIndex){ this.totalPrice = 0 }
			}
		},
		package_period(val){
			this.getPackageTopUp(val);
		}
	},

	computed : {
		isButtonDisable() {
			return (
				this.choosedIndex == null &&
				(this.custom_swc == null || parseInt(this.custom_swc) < 50)
			)
		}
	},

	methods : {
		directWhatsapp() {
			let phoneNumber = "6282126719955"
            let url = "https://wa.me/" + phoneNumber;
            window.open(url, "_blank");
		},
		checkDiscountUserReferral(){
			checkDiscount().then((res)=>{
				this.status_discount_user = res.status;
				if(res.status){
					this.list_data_discount = res.data;
				}
			}).catch((err)=>{
				console.log(err)
			})
		},
		formatPrice(price) {
            // Function yang digunakan untuk memformat harga
            return `${price.toLocaleString()}`;
        },

        chooseSwc(index, value) {
			this.custom_swc = null
            if(this.choosedIndex == index){
                this.totalPrice = 0
                this.choosedIndex = null;
            } else {
                this.choosedIndex = index;
                this.totalPrice = value
            }
        },

		getPackageTopUp(bill_type){
			this.load_package = true;

			this.listPackageTopUp = [
				{
					category_id: "64251357e2f349376f831cd7",
					package_category_name: "Special Deals",
					package_list: [],
					_id: "64251357e2f349376f831cd7"
				},
				{
					category_id: "65275a0297fd12364b24e29a",
					package_category_name: "Extra Credits",
					package_list: [],
					_id: "65275a0297fd12364b24e29a"
				},
			]
			
			packageTopUpCeator(bill_type)
			.then((response) => {
				if(response.status == true) {
					this.listPackageTopUp = response.data
					this.load_package = false

					this.$nextTick(() => {
						for (const index of this.$refs.sliders.keys()) {
							this.handleSliderHeaderVisibility(index);
						}

						window.addEventListener('resize', this.handleAllSliderHeaderVisibility);
					});
				} else {
					this.listPackageTopUp = []
					this.load_package = false
				}
			})
			.catch((err) => {
				console.log(err)
			})
		},

		toSearchVoucher(){

		},

		createInvoice(id, community_id, bill_type){
			this.load_topup = true;

			let formData = {
				package_id : id,
				community_id : community_id ? community_id : null,
				bill_type : bill_type ? bill_type : null
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.load_topup = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
				}
			})
			.catch((err) => {
				this.load_topup = false;
				console.log(err)
			})
		},

		checkStatusPin(id){
			this.id_package = id
			checkStatusPin()
			.then((response) => {
				if(response.status) {
					console.log(response)
					let formData = {
						package_id : id
					}
					createSwcPayment(formData)
					.then((res) => {
						if(res.status) {
							this.$router.push({ name : 'Swc-Payment', query : { key : res.data.top_up_swc_transaction_id } })
						} else if (res.status == false && res.error_code == 1) {
							this.tipe_package = "Creator";
							this.$bvModal.show('package-error-msg')
						}else if (res.status == false && res.error_code == 2) {
							this.tipe_package = "Professional";
							this.$bvModal.show('package-error-msg')
						}
						 else if(res.status == false && res.status_code == 204){
							this.$bvModal.show('modal-swc__insufficient');
						}
						else {
							this.alertFail(res.message)
						}
					})
				} else {
					this.$bvModal.show('setup-pin-modal')
				}
			})
		},

		getSwcPrice(){
			this.load_swc_price = true
			checkPriceSwc()
			.then((response) => {
				if(response.status) {
					this.swc_price = response.data
					this.load_swc_price = false
				} else {
					this.load_swc_price = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_swc_price = false
			})
		},

		postSwcRequest(){
			this.load_topup = true

			let formData = {
				swc_request : this.custom_swc
			}
			customSwcRequest(formData)
			.then((response) => {
				if(response.status){
					this.load_topup = false
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
				} else {
					this.load_topup = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_topup = false
			})
		},

		getStatusSubscriptions(){
            this.load_subscriptions = true;

            subscriptionsStatus()
            .then((response) =>{
                if(response.status == true ){
                    this.status_subscriptions = response.data
                    this.load_subscriptions = false;
                } else if(response.status == false && response.message == 'No Allocations') {
                    this.load_subscriptions = false;
                } else {
                    this.load_subscriptions = false;
                    console.log(response)
                }
            })
        },

		getPackageSwc() {
			this.load_package_swc = true
			getPackageTopupSwc()
			.then((response) => {
				if(response.status){
					this.listPackageSwc = response.data
					this.load_package_swc = false
				} else {
					this.load_package_swc = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_package_swc = false
			})
		},

		next() {
			this.$bvModal.hide('topup-swc-modal')
			if(this.choosedIndex) {
				this.createInvoice(this.choosedIndex)
			} else if(this.custom_swc) {
				this.postSwcRequest()
			}
		},

		swcCustomFocus() {
			if(this.choosedIndex) {
				this.totalPrice = 0
				this.choosedIndex = null;
			}
		},

		requestPin(){
			requestSetPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('verif-code-modal')
					this.$bvModal.hide('setup-pin-modal')
				} else {
					if(response.response?.response?.status == 429) {
						this.alertFail("Too many requests, please try again later.")
					}
				}
			})
		},

		openCreatePin() {
			this.$bvModal.hide('verif-code-modal')
			this.$bvModal.show('topup-create-pin-modal')
		},

		handleChoosedIndex(val) {
			this.custom_swc = null
			if(this.choosedIndex == val._id){
                this.totalPrice = 0
                this.choosedIndex = null;
            } else {
                this.choosedIndex = val._id
				this.totalPrice = val.price_idr
            }
		},

		handleInputSwc(val) {
			const parsedValue = parseInt(val);
			if(!isNaN(parsedValue)) {
				this.custom_swc = parsedValue
			} else {
				this.custom_swc = null
			}
		},

		handleCloseTopUp() {
			this.totalPrice = 0
            this.choosedIndex = null;
			this.custom_swc = null
			this.is_enough = false
			this.$bvModal.hide('topup-swc-modal')
		},

		handleCreateCommunity() {
			this.$router.push({ name : 'Create-Community' })
		},

		communityCheck(id) {
			// Community Check before buy package
			checkCommunity()
			.then((response) => {
				if(response.status) {
					// logic jika user telah memiliki community
					this.$bvModal.show('choose-community-modal')
					this.id_package = id
				} else {
					// logic jika user belum memiliki community
					this.$bvModal.show('create-community-modal')
				}
			})
		},

		handleChooseCommunity(val) {
			this.$bvModal.hide('choose-community-modal')
			this.createInvoice(this.id_package, val)
		},

		getCommunity() {
			this.load_community = true;

			getOwnershipCommunity()
			.then((response) => {
				if(response.status) {
					this.list_community = response.data
					this.load_community = false
				} else {
					this.list_community = []
					this.load_community = false
				}
			})
			.catch((err) => {
				this.load_community = false
				console.log(err)
			})
		},

		showTooltip(index) {
			this.activeTooltip = index
		},

		hideTooltip() {
			this.activeTooltip = null
		},
		showTooltipPc(index) {
			this.activeTooltipPc = index
		},

		hideTooltipPc() {
			this.activeTooltipPc = null
		},

		handleMousedownSlider(index, e) {
			this.slidersStartX.splice(index, 1, e.pageX - this.$refs.sliders[index].offsetLeft);
			this.slidersStartScrollLeft.splice(index, 1, this.$refs.sliders[index].scrollLeft);
		},

		handleMousemoveSlider(index, e) {
			if (this.slidersStartX[index] !== null) {
				const x = e.pageX - this.$refs.sliders[index].offsetLeft;
				const scrollX = this.slidersStartScrollLeft[index] - x + this.slidersStartX[index];
				this.$refs.sliders[index].scrollLeft = scrollX;
			}
		},

		handleMouseupSlider(index) {
			this.slidersStartX.splice(index, 1, null);
		},

		handleMouseleaveSlider(index) {
			this.slidersStartX.splice(index, 1, null);
		},

		handleSliderHeaderVisibility(index) {
			const { scrollLeft } = this.$refs.sliders[index];

			const startFade = 75;
			const endFade = 225;
			const multiplier = 100 / (endFade - startFade);

			let showPercentage;

			if (scrollLeft <= startFade) {
				showPercentage = 100;
			} else if (scrollLeft >= endFade) {
				showPercentage = 0;
			} else {
				showPercentage = Math.round(100 - ((scrollLeft - startFade) * multiplier));
			}

			this.slidersHeaderShowPercentage.splice(index, 1, showPercentage);
		},

		handleAllSliderHeaderVisibility() {
			for (const index of this.$refs.sliders.keys()) {
				this.handleSliderHeaderVisibility(index);
			}
		},

		formatHarga(angka) {
			if (angka >= 1000000) {
				return (angka / 1000).toFixed(0) + 'k';
			} else if (angka >= 1000) {
				return (angka / 1000).toFixed(0) + 'k';
			} else {
				return angka.toLocaleString('id-ID', { maximumFractionDigits: 0 });
			}
		},


		whatsapp(){
            let phoneNumber = "6282126719955"
            let url = "https://wa.me/" + phoneNumber;
            window.open(url, "_blank");
        },

		showAssistant() {
			this.isAssistantHovered = true;
		},
		hideAssistant() {
			this.isAssistantHovered = false;
		},
	}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&display=swap');

.tooltip--btn {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1 !important;
  background-color: #1F1F1F !important;
}

.tooltip--btn .tooltip--btntext, .position-relative .tooltip--btntext{
	visibility: hidden;
    width: 194px;
    background-color: #1F1F1F;
    color: #8C8C8C;
    text-align: left;
    border-radius: 4px;
    padding: 13px;
    font-weight: 300;
    font-size: 10px;
    position: absolute;
	left: 31px;
    top: 27px;
    z-index: 1;
}

.tooltip--btn:hover .tooltip--btntext {
  visibility: visible;
}

.v-hidden{
	visibility: hidden;
}
.slider--switch{
  display: inline-block;
  margin: auto 0;
  font-size: 0;
  border: 1px solid #585859;
  width: 335px;
  position: relative;
  border-radius: 25px;
}

.slider--switch label[for='monthly']{
  width: 40%;
}
.slider--switch label[for='annual']{
	width: 60%;
}
.slider--switch label{
	position: relative; 
	cursor: pointer;
	font-size: 16px;
	text-align: center;
	padding: 7px 0;
	transition: all 0.3s ease;  
	margin: 0;
	color: #fff;
	font-weight: 600;
}

.slider--switch input#monthly:checked ~ .switch--bar{
	width: 40%;
	height: 78%;
	position: absolute;
	top: 4px;
	left: 1%;
	z-index: -1;
	background-color: #fff;
	transition: all 0.3s ease;
	border-radius: 19.5px;
	color: #000 !important;
}
.slider--switch input#annual:checked ~ .switch--bar{
	width: 58%;
	height: 78%;
	position: absolute;
	top: 4px;
	left: 40%;
	z-index: -1;
	background-color: #fff;
	transition: all 0.3s ease;
	border-radius: 19.5px;
	color: #000 !important;
}

#monthly:checked + label:before{
  left: 0;
}

#monthly:checked + label,
#annual:checked + label{
  color: #000;
}

.special--deals{
	width: 255px;
}

.extra--credits{
	width: 232px;
}

.btn--outline-white{
	transition: 0.5s;
	background-color: transparent;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
	color: white;
	font-weight: 900;
	font-size: 15px;
	transition: background-color 0.25s;
}

.active--for1month{
	color: #C4C4C4;
	font-size: 12px;
	text-align: left;
}
.discount--text{
	color: #8C8C8C;
	font-family: "Avenir Next";
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration: line-through;
}

.slider__wrapper{
	height: 340px;
}
.slider__wrapper--icons{
	height: 220px;
}
.badge--save{
	color: #FFF;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	border-radius: 14px;
	background: rgba(109, 117, 246, 0.30);
	padding: 4px 8px;
    margin-left: 0.5rem;
}
.ptct--list {
	list-style: none;
	counter-reset: level-1;
	gap: 2px;
}

.ptct--list li > .ptct--item::before {
	counter-increment: level-1;
  	content: counter(level-1)".";
}

.top-up__container {
	min-height: 100vh;
}

.top-up__back-button {
	gap: 8px;
}

.top-up__title {
	font-size: 20px;
	font-weight: 500;
}

.top-up__container__nav {
	border-bottom: 0.1px solid #2D2D2D;
	padding: 10px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.top-up__container__nav .nav-tabs {
    border: none;
	color: white;
}

.top-up__container__nav li button{
    padding: 1.3rem 0.5rem;
    border: none;
    background-color: transparent;
    color: #8C8C8C;
}

.top-up__container__nav li .active {
    color: #6D75F6;
    border-bottom: 2px solid #6D75F6 ;
}

.top-up__container__nav li h5 {
	font-weight: bold;
}

.top-up__container__nav li .active h5 {
	font-weight: bold;
}

.card-nav__wrapper {
	display: flex;
	align-items: center;
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.card-nav__swc {
	background-color: #121418;
	border-radius: 6px;
	padding: 10px 20px;
	backdrop-filter: blur(4px);
	display: flex;
	align-items: center;
}

.card-nav__subtitle {
	font-size: 12px;
	color: white;
	font-weight: normal;
}

.card-nav__title {
	font-size: 18px;
	color: white;
	font-weight: 900;
}

.top-up__container-inner {
	padding: 20px 0;
	text-align: left;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
    grid-gap: 1rem;
}

.card-swc__inner {
	display: grid;
}

.card-swc {
    border: 1px solid #1C1C1C;
    border-radius: 10px;
    display: grid;
}

.choosed-swc {
    border-color : #6D75F6;
}

.form-control{
    background-color: transparent;
    color: white;
    border-color: #383838;
}

.input-swc label {
    position: relative;
    width: 100%;
}

.input-swc label::before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 13px;
    bottom: 0;
    width: 40px;
    background: url("~@/assets/images/icons/ic_coin_swc_1.png") center / contain no-repeat;
    background-size: 20px;
    z-index: 10;
}

.input-swc label input {
    padding-left: 50px;
    height: 2.8rem;
	border-radius: 8px;
	box-shadow: none;
	transition: border 0.25s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-purple{
    color: white;
    min-height: 40px;
    background-color: #6D75F6;
    border: 1px solid #6D75F6
}

.package__title {
	font-size: 24px;
	color: white;
	font-weight: 700;
}

.card-package__wrapper {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.card-package {
	padding: 15px;
	background-color: #121418;
	border-radius: 6px;
	backdrop-filter: blur(4px);
	/* display: flex;
	align-items: center;
	justify-content: center; */
}

.card-package__container:last-child {
	margin-right: 40px !important;
}

.bg_multilingual {
	background-image: url("~@/assets/images/bg_multilingual_2.png");
	background-repeat: no-repeat;
	background-color: #121418;
	background-position-x: center;
	background-size: contain;
}

.btn-pricing {
	transition: 0.5s;
	background-color: #6D75F6;
	border: none;
	border-radius: 4px;
	color: white;
	font-weight: 900;
	font-size: 15px;
	transition: background-color 0.25s;
}

.btn-pricing:hover {
	background-color: #545EED;
}

.button__item-buy-now {
	font-weight: 900;
	font-size: 15px;
}


.card-package__icon {
	width: 54px;
	height: 54px;
}

.validator__input {
	font-size: 12px;
	margin-bottom:0;
	margin-top: 0.5rem;
	color: #DF2723;
	font-weight: 400;
}

.vertical-line {
    border-left: 0.5px solid white;
    height: 35px; /* Adjust the height as needed */
	margin: 0px 15px;
}

.btn-topup {
	display: grid;
	justify-items: center;
    align-items: center;
	color: white;
	gap: 3px;
	padding: 0;
	width: 65px;
}

.btn-topup:disabled {
	color: #8C8C8C;
}

.list-action__swc {
	display: flex;
}

.package--title__container {
	gap: 10px;
}

.ptc--tooltip {
	padding: 25px;
	background: #2D2D2D;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 12.9296px;
	position: absolute;
	left: 40px;
	gap: 10px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	width: 383px;
	visibility: hidden;
	opacity: 0;
	right: 100%;
	transform-origin: center right;
  	transition: all 0.25s;
}
.tooltips--content{
	padding: 25px;
	background: #1F1F1F;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	position: absolute;
	z-index: 2;
	visibility: hidden;
	opacity: 0;
	transform-origin: center right;
  	transition: all 0.25s;
	right: 0;
}
.pc--list li{
	width: fit-content;
}
.pc--subtitle{
	font-size: 10px;
	color: #8C8C8C;
	text-align: left;
}
.pc--item{
	font-size: 10px;
	color: #fff;
	text-align: left;
}
.pc--tooltip:hover .tooltips--content{
	visibility: visible;
	opacity: 1;	
}

.ptc--tooltip--show, .pc--tooltip__show {
	visibility: visible;
	opacity: 1;
}

.ptct--subtitle, .ptct--desc{
	font-size: 12px;
	font-weight: 400;
}

.ptct--title, .pc--title {
	font-size: 20px;
	font-weight: 900;
}

.package + .package {
	margin-top: 48px;
}

.slider {
	flex-wrap: nowrap;
	/* overflow-x: auto; */
	cursor: default;
	height: fit-content;
}

.slider:active {
	cursor: grabbing;
}

.slider::-webkit-scrollbar {
	display: none;
}

.slider > *:first-child {
	margin-left: 280px;
}

.slider__header {
	gap: 16px;
	inset: 0 auto 0 40px;
	width: 285px;
	padding: 32px 80px 32px 32px;
	border-radius: 8px;
	transition: opacity 0.25s, translate 0.25s;
}

.package:nth-child(1) .slider__header {
	/* background-color: #E9E0FF; */
	background-image: url('~@/assets/images/background/bg-pt-2.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.package:nth-child(2) .slider__header {
	/* background-color: #CDFFCF; */
	background-image: url('~@/assets/images/background/q-gen-wb.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.package:nth-child(3) .slider__header {
	/* background-color: #FFD1D1; */
	background-image: url('~@/assets/images/background/bg-pt-3.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.slider__header-title {
	font-size: 18px;
	font-weight: 400;
}

.slider__header-text--highlight {
	font-size: 26px;
	font-family: Wallpoet;
	text-transform: uppercase;
	line-height: 1;
}

.package:nth-child(1) .slider__header-text--highlight {
	color: #897CEF;
}

.package:nth-child(2) .slider__header-text--highlight {
	color: #897CEF;
}

.package:nth-child(3) .slider__header-text--highlight {
	color: #ED7272;
}

.slider__header-text {
	color: #6A6A6A;
}


.prevent-select {
	user-select: none;
	font-size: 18px;
}

.pi--icon {
	width: 15px;
	height: 15px
}

.sd__text {
	color: #FFF;
	font-size: 25px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}
.package--title {
	color: #FFF;
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}
.sd__text {
	color: #FFF;
	font-size: 25px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}

.sd__text--sm{
	color: #FFF;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.sd__text-discount {
	color: #585859;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: line-through;
}

.sd__img, .alc__inner--logo {
	position: absolute;
    left: 0;
    right: 0;
	top: -8px;
    margin: 0 auto;
	width: 250px;
}

.sd__price {
	margin-top: 2.2rem;
}

.sd__text-list {
	color: #FFF;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.sd__item-inner {
	gap: 8px;
}

.button__item-buy-now {
	color: #FFF;
	font-style: normal;
	font-weight: 900;
	width: 100%;
	background-color: #DF2723;
	border-radius: 4px;
	border: none;

	transition: background-color 0.25s;
}

.button__item-buy-now:hover {
	background-color: #ec1814;
}


.button__item--outline__active, .button__item--outline__active:disabled {
	color: #FFF;
	font-style: normal;
	font-size: 12px;
	font-style: normal;
	font-weight: 900;
	width: 100%;
	background-color: #2d2d2d;
	border-radius: 4px;
	border: none;
	padding: 10px 0px;
	cursor: default !important;
}
.button__item-active, .button__item-active:disabled {
	color: #585859;
	font-style: normal;
	font-size: 15px;
	font-style: normal;
	font-weight: 900;
	width: 100%;
	background-color: #2d2d2d !important;
	border-radius: 4px;
	border: none;
	cursor: default !important;
}

.footer__text {
	color: #C4C4C4;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.slider__header-text--discount {
	color: #6D75F6;
	font-family: 'IBM Plex Serif';
	font-size: 77.176px;
	font-weight: 700;
	line-height: 75px;
}

.slider__header-text--discount--sub {
	color: #6D75F6;
}

.active__label {
    bottom: 1px;
}

.alc__header {
	background-color: white;
	border-radius: 6px 6px 0 0;
	text-align: left !important;
	gap: 6px;
}

.alc__header--text {
	color: #000;
	text-align: center;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.alc__header--spread {
	width: 0.5px;
	height: 20px;
	background: #C5C5C5;
}

.alc__header--badge {
	width: 54.108px;
	height: 54.647px;
	right: 10px;
    top: 10px;
}

.alc__inner {
	padding: 15px;
}

.alc__inner--logo {
	width: 200px;
}

.alc__inner--text-small {
	color: #FFF;
	text-align: center;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.alc__inner--text-big {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.alc__inner--text-bigger {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.alc__inner--desc {
	gap: 2px;
}

.sp__inner {
	gap: 6px;
}

.sp__extra {
	gap: 2px;
}

.vc__inner {
	gap: 4px;
}

.button--assistant {
	color: #FFF;
	font-size: 18px;
	font-weight: 600;
}

.assistant--wrap {
	position: sticky;
	bottom: 20px;
	right: 20px;
}

.assistant--text__wrapper {
	border-radius: 20.5px 0 0 20.5px;
	color: #FFF;
	font-size: 18px;
	font-weight: 600;
	background: #2D2D2D;
	padding: 10px 12px;
	right: 56px;
    z-index: -1;
	width: 300px;
    top: 12px;

	/* visibility: hidden;
	opacity: 0;
	transition: width 0.25s, visibility 0.25s, opacity 0.25s; */
	opacity: 0;
	transform: translateX(5%);
  	transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.assistant--text__wrapper.visible {
	opacity: 1;
  	transform: translateX(0);
}
</style>

<style>
.text-primary {
	color: #6D75F6 !important
}

.overflow-x-hidden {
	overflow-x: hidden;
}
</style>